import "../CommonStyles.css";

const Surveillance = () => (
  <div>
    <div className="placeholder-block">
      <div>
        <li>
          Предоставляем услуги видеонаблюдения, в том числе как стационарного,
          так и на катерах, яхтах, лодках, любого исполнения с ночным видением,
          тепловизором, записью на носитель, выводом на существующий монитор или
          установкой нового, возможность удаленного доступа к изображению в
          любой момент времени из любой точки Земли.
        </li>

        <li>
          Мы расширяем границы, соединяя людей и инновации, делая связь
          стабильной, а вашу жизнь - более безопасной
        </li>
      </div>
    </div>
    {/* <div className="placeholder-block">Девайс 1 (Название, фото, описание)</div> */}
    {/* <div className="placeholder-block">Девайс 2 (Название, фото, описание)</div> */}
    <div className="placeholder-block">Продукты видеонаблюдение</div>
  </div>
);

export default Surveillance;
