import "../CommonStyles.css";

const Support = () => (
  <div>
    <div className="placeholder-block">Форма запроса тех. поддержки</div>
    <div className="placeholder-block">Каталог Мануалов с поиском</div>
  </div>
);

export default Support;
