import "../CommonStyles.css";
import LookMoreButton from "../components/LookMoreButton";

const Home = () => (
  <div>
    <div className="placeholder-block">
      Шапка-слайдер
      <br />
      <div>
        <br /> (MKL Telecom – компания, предлагающая услуги доступа в интернет,
        радиосвязи и видеонаблюдения для комфортной работы и отдыха как на воде,
        так и на суше.)
      </div>
    </div>
    {/* <div className="placeholder-block">Новости</div> */}
    <div className="placeholder-block">
      Наши направления:
      <div>
        <br />
        <br />
        1. Стабильный и надежный доступ к высокоскоростному интернету.{" "}
        <LookMoreButton link="/incarnet" />
        <br />
        <br />
        2.Радиосвязь
        <LookMoreButton link="/incarnet" />
        <br />
        <br />
        3. Видеонаблюдение Мы расширяем границы, соединяя людей и инновации,
        делая связь стабильной, а вашу жизнь - более безопасной, благодаря всему
        спектру оказываемых нами услуг. MKL Telecom - Ваш надежный партнер в
        области технологий <LookMoreButton link="/incarnet" />
        <br />
        <br />
      </div>
    </div>
    {/* <div className="placeholder-block">
      <div>
        Радиосвязь вступление
        <br />
        <br />
        <LookMoreButton link="/radiocommunication" />
      </div>
    </div>
    <div className="placeholder-block">
      <div>
        Видеонаблюдение вступление
        <br />
        <br />
        <LookMoreButton link="/surveillance" />
      </div>
    </div> */}
    <div className="placeholder-block">
      <div>
        Форма обратной связи
        <br />
        <br />
        <LookMoreButton link="/support" />
      </div>
    </div>
  </div>
);

export default Home;
