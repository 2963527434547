import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"; // Don't forget to create or modify App.css as needed
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Incarnet from "./pages/Incarnet";
import Radiocommunication from "./pages/Radiocommunication";
import Surveillance from "./pages/Surveillance";
import Support from "./pages/Support";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/incarnet" element={<Incarnet />} />
          <Route path="/radiocommunication" element={<Radiocommunication />} />
          <Route path="/surveillance" element={<Surveillance />} />
          <Route path="/support" element={<Support />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
