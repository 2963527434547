import { Link } from "react-router-dom";

const Header = () => (
  <div className="header">
    <h1 className="logo">Logo</h1>
    <nav className="navigation">
      <ul>
        <li>
          <Link to="/">ГЛАВНАЯ</Link>
        </li>
        <li>
          <Link to="/incarnet">INCARNET</Link>
        </li>
        <li>
          <Link to="/radiocommunication">РАДИОСВЯЗЬ</Link>
        </li>
        <li>
          <Link to="/surveillance">ВИДЕОНАБЛЮДЕНИЕ</Link>
        </li>
        <li>
          <Link to="/support">ТЕХ. ПОДДЕРЖКА</Link>
        </li>
      </ul>
    </nav>
  </div>
);

export default Header;
