import "../CommonStyles.css";

const Incarnet = () => (
  <div>
    <div className="placeholder-block">
      <div>
        Мы предоставляем стабильный и надежный доступ к высокоскоростному
        интернету. Существующие решения реализованы как в мобильном, так и
        стационарном исполнении, мы устанавливаем оборудование на катер, машину
        и любое другое средство передвижения, а также в жилом секторе, частной
        собственности, область применения может быть безгранична. Мы предлагаем
        продукты, которые просты в использовании и легки в управлении, используя
        мощности всех доступных мобильных операторов одновременно, чтобы вы
        всегда оставались на связи. Мы обеспечиваем связь, которая так
        необходима.
        <br />
        <br />
        Наши Маршрутизаторы объединяют потоки мобильного интернета от нескольких
        провайдеров в единый бесперебойный канал доступа, чтобы вы всегда
        оставались онлайн.
        <li>
          Иконками или тегами написать способы использования: яхты, катера,
          частный сектор, экстремальный отдых
        </li>
        <li>
          Маршрутизаторы (галерея моделей). Кнопка «заказать консультацию»
        </li>
      </div>
    </div>
    <div className="placeholder-block">
      <div>
        Линейка маршрутизаторов <br />
        <br />
        Маршрутизатор предназначен для предоставления надежного
        высокоскоростного канала передачи данных в транспортных средствах за
        счет комбинации всех доступных в регионе стандартов (3G/4G LTE/CDMA) и
        операторов. В отличие от традиционных решений, программно-аппаратный
        комплекс Incarnet, используя технологию агрегирования, автоматически
        управляет распределением и суммированием трафика каналов связи. В
        процессе работы устройства, для осуществления передачи данных и
        получения максимальной скорости, используются все доступные операторы
        связи, производится программное суммирование потоков трафика, прозрачное
        переключение как между базовыми станциями одного оператора, так и между
        сетями разных операторов, что обеспечивает значительное преимущество над
        классическими решениями. <br />
        <br />
        Маршрутизатор мультиоператорный, Incarnet Vi4H
        <br />
        описание: с возможность нажать подробнее или технические характеристики
        и комплектацию и физ характеристики
        <br />
        <br />
        Маршрутизатор мультиоператорный, Incarnet Vi8-UR
        <br />
        Описание: с возможность нажать подробнее или технические характеристики
        и комплектацию и физ характеристики
        <br />
        <br />
      </div>
    </div>

    <div className="placeholder-block">
      Дополнительно: форма фактор «сумка» и «кейс»
    </div>

    <div className="placeholder-block">
      <div>
        Технические характеристики:
        <br />
        <br />
        Маршрутизатор обеспечивает организацию и бесперебойное поддержание всех
        3G/ LTE каналов передачи данных в зависимости от задействованных модемов
        и SIM-карт при наличии покрытия сотовой сети. Используя алгоритмы
        агрегации, устройство объединяет в единый канал передачи данных все
        активные3G/LTE каналы. Наличие собственной LAN предоставляет возможность
        подключения стационарных средств вычислительной техники по интерфейсу
        Gigabit Ethernet 10/100/1000 Base-T и по интерфейсу WI-Fi стандарта
        802.11n/ас. Маршрутизатор обладает встроенным функционалом работы с
        GPS/ГЛОНАСС
        <br />
        <br />
        <li>
          <strong>Комплектация</strong> - маршрутизатор, 1 шт; - антенна Wi-Fi
          2,4/5 ГГц, 4 шт; - комплект для подключения к электрической сети
          автомобиля, 1шт; - блок питания для подключения к сети 220В
          (опционально); - антенна 3G/LTE - 6-16шт (в зависимости от модели
          устройства); - антенна GPS (опционально); - документация, 1 комплект
        </li>
        <li>
          <strong>Физические характеристики</strong> Маршрутизатор имеет
          следующие физические характеристики: - Габаритные размеры изделия – не
          более 217 х 178 х 50 мм (Д х Ш х В); - Вес изделия - не более 2,5 к
        </li>
        <br />
        Может дать ссылку на руководство?
      </div>
    </div>
    {/* <div className="placeholder-block">Девайс 2 (Название, фото, описание)</div>
    <div className="placeholder-block">Девайс 3 (Название, фото, описание)</div>
    <div className="placeholder-block">Девайс 4 (Название, фото, описание)</div>
    <div className="placeholder-block">
      Перечисление остальных девайсов (если нужно)
    </div> */}
  </div>
);

export default Incarnet;
