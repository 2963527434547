// #Button component with title="Подробнее" and onClick={this.props.onClick}

import React from "react";
import { Link } from "react-router-dom";

const LookMoreButton = (props) => (
  <div className="button">
    <Link to={props.link} className="look-more-button">
      {props.title ? props.title : "Подробнее"}
    </Link>
  </div>
);

export default LookMoreButton;
