import "../CommonStyles.css";

const Radiocommunication = () => (
  <div>
    <div className="placeholder-block">
      <div>
        Радиосвязь с различными исполнениями, узкополосная связь, гибридные
        рации с функциями узкополосной связи, РТТ без ограничений территории,
        возможность использования радиосвязи для общения по видео. Создание
        отдельных групп пользователей, операторское приложение с возможностью
        отслеживания каждой рации. Полное шифрование передаваемой информации.
        Системы профессиональной мобильной радиосвязи: от аналоговых до
        цифровых, от стандартных до магистральных, от портативных и мобильных до
        системного оборудования. <br />
        <br />
        <div>Линейка станций (ТЕ320 , ТЕ590, ТЕ620, ТЕ580РD</div>
      </div>
    </div>
    {/* <div className="placeholder-block">Девайс 2 (Название, фото, описание)</div>
    <div className="placeholder-block">Девайс 3 (Название, фото, описание)</div>
    <div className="placeholder-block">Девайс 4 (Название, фото, описание)</div>
    <div className="placeholder-block">
      Перечисление остальных девайсов (если нужно)
    </div> */}
  </div>
);

export default Radiocommunication;
